import React, { useState } from "react";
import { Link } from "react-router-dom";

import { EXTERNAL_URLS } from "../../constants/appConstants";

import Logo from "../../assets/images/logo.svg";
import hamIcon from "../../assets/images/ham-icon.svg";
import crossIcon from "../../assets/images/cross-icon.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header>
      <img src={Logo} alt="Happypropy's logo" />
      <nav className="menu">
        <a href="#property-insights" className="nav-link">
          Property Insights
        </a>
        <a href="#advantages" className="nav-link">
          Advantages
        </a>
        <a href="#security" className="nav-link">
          Security
        </a>
      </nav>
      <div className="options">
        <Link
          to={EXTERNAL_URLS.HappyProppyApp}
          target="_blank"
          className="nav-link"
        >
          Sign In
        </Link>

        <Link className="btn" target="_blank" to={EXTERNAL_URLS.SignUp}>
          Get started now
        </Link>
      </div>
      <div className="ham" onClick={() => setIsMenuOpen(true)}>
        <img className="ham-btn" src={hamIcon} alt="Open menu button" />
      </div>

      <div
        className="mobile-menu"
        style={{
          display: isMenuOpen ? "flex" : "none",
        }}
      >
        <div className="controls">
          <img src={Logo} alt="Happypropy's logo" />
          <div className="ham" onClick={() => setIsMenuOpen(false)}>
            <img className="ham-btn" src={crossIcon} alt="Close menu button" />
          </div>
        </div>
        <nav className="nav">
          <a
            href="#property-insights"
            className="nav-link"
            onClick={() => setIsMenuOpen(false)}
          >
            Property Insights
          </a>
          <a
            href="#advantages"
            className="nav-link"
            onClick={() => setIsMenuOpen(false)}
          >
            Advantages
          </a>
          <a
            href="#security"
            className="nav-link"
            onClick={() => setIsMenuOpen(false)}
          >
            Security
          </a>
        </nav>
        <div className="options">
          <Link
            to={EXTERNAL_URLS.HappyProppyApp}
            target="_blank"
            className="nav-link"
          >
            Sign In
          </Link>

          <Link className="btn" target="_blank" to={EXTERNAL_URLS.SignUp}>
            Get started now
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
