import React, { useState, useEffect, useRef } from "react";

import FeatureCard from "../featureCard/component";

import previewDashboard from "../../assets/images/preview-dashboard.png";
import previewProperty from "../../assets/images/preview-property.png";
import previewChat from "../../assets/images/preview-chat.png";
import previewTenant from "../../assets/images/preview-tenant.png";
import previewReminders from "../../assets/images/preview-reminders.png";
import previewMaintenance from "../../assets/images/preview-maintenance.png";
import graphIcon from "../../assets/images/graph-icon.svg";
import houseIcon from "../../assets/images/house-icon.svg";
import userIcon from "../../assets/images/user-icon.svg";
import chatIcon from "../../assets/images/message-bubble-icon.svg";
import maintenanceIcon from "../../assets/images/mechanic-key-icon.svg";
import checkIcon from "../../assets/images/check-icon.svg";

const PropertyInsightsSection = () => {
  const landingView = document.querySelector("#root");
  const insightsDesktop = useRef(null);
  const insightsMobile = useRef(null);
  const [activeCard, setActiveCard] = useState(1);
  const [activeMobileCard, setActiveMobileCard] = useState(1);

  useEffect(() => {
    const insightDesktopSection = insightsDesktop.current;

    landingView.addEventListener("scroll", scrollHandler);
    insightDesktopSection.addEventListener("mousemove", parallax);
    return () => {
      landingView.removeEventListener("scroll", scrollHandler);
      insightDesktopSection.removeEventListener("mousemove", parallax);
    };
  }, []);

  useEffect(() => {
    const insightDesktopSection = insightsDesktop.current;
    const imagesUp = insightDesktopSection.querySelectorAll(".hide-up");
    const imagesRight = insightDesktopSection.querySelectorAll(".hide-right");
    const cardHideLeft = insightDesktopSection.querySelectorAll(
      ".feature-card.hide-left"
    );
    const activeImage = insightDesktopSection.querySelector(
      ".follow-mouse.active"
    );
    const activeCard = insightDesktopSection.querySelector(
      ".feature-card.active"
    );

    imagesUp.forEach((image) => {
      image.style.top = "-150%";
    });

    imagesRight.forEach((image) => {
      image.style.right = "-150%";
    });

    cardHideLeft.forEach((card) => {
      card.style.left = "-300%";
      card.style.position = "absolute";
    });

    activeImage.style.top = "1rem";
    activeImage.style.right = "0";
    activeCard.style.left = "0";
    activeCard.style.position = "relative";
  }, [activeCard]);

  useEffect(() => {
    const insightMobileSection = insightsMobile.current;

    const cardWrapperActive = insightMobileSection.querySelector(
      ".feature-card-content:has(>*.active)"
    );
    const cardsWrapperHide = insightMobileSection.querySelectorAll(
      ".feature-card-content.hide-left"
    );
    cardWrapperActive.style.position = "relative";
    cardWrapperActive.style.top = "0";
    cardWrapperActive.style.left = "0";
    cardWrapperActive.style.height = `100%`;

    cardsWrapperHide.forEach((card) => {
      card.style.position = "absolute";
      card.style.left = "-250%";
    });
  }, [activeMobileCard]);

  function parallax(e) {
    const windowWidthCenter = window.innerWidth / 2;
    const windowHeightCenter = window.innerHeight / 2;
    this.querySelectorAll(".follow-mouse").forEach((shift) => {
      const x = (e.pageX - windowWidthCenter) / 100;
      const y = (e.pageY - windowHeightCenter) / -100;
      shift.style.transform = `rotateX(${y}deg) rotateY(${x}deg)`;
    });
  }

  function scrollHandler() {
    const scroll = landingView.scrollTop;
    const header = landingView.querySelector("header");
    const headerHeight = header.offsetHeight;
    const propertyInsights =
      landingView.querySelector(".insights").offsetTop - headerHeight;
    const mobilePropertyInsights =
      landingView.querySelector(".mobile-insights").offsetTop -
      headerHeight * 4;

    const scrollCardSwap = 500;
    const scrollCardSwapMobile = 600;

    if (
      scroll > propertyInsights &&
      scroll < propertyInsights + scrollCardSwap
    ) {
      setActiveCard(1);
    }
    if (
      scroll > propertyInsights + scrollCardSwap &&
      scroll < propertyInsights + scrollCardSwap * 2
    ) {
      setActiveCard(2);
    }
    if (
      scroll > propertyInsights + scrollCardSwap * 2 &&
      scroll < propertyInsights + scrollCardSwap * 3
    ) {
      setActiveCard(3);
    }
    if (
      scroll > propertyInsights + scrollCardSwap * 3 &&
      scroll < propertyInsights + scrollCardSwap * 4
    ) {
      setActiveCard(4);
    }
    if (
      scroll > propertyInsights + scrollCardSwap * 4 &&
      scroll < propertyInsights + scrollCardSwap * 5
    ) {
      setActiveCard(5);
    }
    if (
      scroll > propertyInsights + scrollCardSwap * 5 &&
      scroll < propertyInsights + scrollCardSwap * 6
    ) {
      setActiveCard(6);
    }

    if (
      scroll > mobilePropertyInsights &&
      scroll < mobilePropertyInsights + scrollCardSwapMobile
    ) {
      setActiveMobileCard(1);
    }
    if (
      scroll > mobilePropertyInsights + scrollCardSwapMobile &&
      scroll < mobilePropertyInsights + scrollCardSwapMobile * 2
    ) {
      setActiveMobileCard(2);
    }
    if (
      scroll > mobilePropertyInsights + scrollCardSwapMobile * 2 &&
      scroll < mobilePropertyInsights + scrollCardSwapMobile * 3
    ) {
      setActiveMobileCard(3);
    }
    if (
      scroll > mobilePropertyInsights + scrollCardSwapMobile * 3 &&
      scroll < mobilePropertyInsights + scrollCardSwapMobile * 4
    ) {
      setActiveMobileCard(4);
    }
    if (
      scroll > mobilePropertyInsights + scrollCardSwapMobile * 4 &&
      scroll < mobilePropertyInsights + scrollCardSwapMobile * 5
    ) {
      setActiveMobileCard(5);
    }
    if (
      scroll > mobilePropertyInsights + scrollCardSwapMobile * 5 &&
      scroll < mobilePropertyInsights + scrollCardSwapMobile * 6
    ) {
      setActiveMobileCard(6);
    }
  }

  return (
    <section className="property-insights" id="property-insights">
      <h1 className="text-center">
        Revolutionize the way you manage your properties with{" "}
        <span className="text-gradient">HappyProppy</span>
      </h1>
      <div className="property-insights-text">
        <p className="text-center text-balanced">
          Our platform combines intuitive property management, instant tenant
          communication via in-app chat, efficient maintenance tracking, and
          timely reminders into one seamless experience. Say goodbye to
          scattered tools and hello to a centralized system that not only
          streamlines your operations but also enhances your tenant
          relationships.
        </p>
        <p className="text-center text-balanced">
          With HappyProppy, you're equipped to manage, engage, and grow your
          property portfolio with ease and confidence.{" "}
        </p>
      </div>
      <div className="scroll-sign">
        <div className="scroll-animated"></div>
      </div>
      <div className="insights" ref={insightsDesktop}>
        <div className="blob-wrapper">
          <div className="blob"></div>
          <div className="blob"></div>
        </div>
        <div className="cards-wrapper">
          <FeatureCard
            icon={graphIcon}
            title="Dashboard at a Glance"
            description="See all the essentials in one place. Landlords get instant insights into rent payments, tenants, upcoming dues, and expected revenue. Tenants can view their current dues and unit details with ease."
            active={activeCard === 1}
            className={activeCard > 1 ? "hide-left" : ""}
          />
          <FeatureCard
            icon={houseIcon}
            title="Property & Unit Management"
            description="Gain a bird's eye view and detailed control over your entire portfolio."
            active={activeCard === 2}
            className={activeCard > 2 ? "hide-left" : ""}
          />
          <FeatureCard
            icon={chatIcon}
            title="In-App Chat for Simplified Communication"
            description="Centralized Messaging: Direct, trackable in-app conversations reduce communication clutter."
            active={activeCard === 3}
            className={activeCard > 3 ? "hide-left" : ""}
          />
          <FeatureCard
            icon={userIcon}
            title="Tenant Directory"
            description="Easily manage tenant profiles with essential contact information and leasing details. Assign tenants to units and set rental terms. All in one place."
            active={activeCard === 4}
            className={activeCard > 4 ? "hide-left" : ""}
          />
          <FeatureCard
            icon={checkIcon}
            title="Reminders Feature"
            description="Never miss a crucial date again with customizable notifications for payments, lease events, and maintenance."
            active={activeCard === 5}
            className={activeCard > 5 ? "hide-left" : ""}
          />
          <FeatureCard
            icon={maintenanceIcon}
            title="Maintenance Requests"
            description="Streamline the process of submitting, tracking, and resolving maintenance issues."
            active={activeCard === 6}
          />
        </div>
        <div className="insight-images">
          <img
            src={previewDashboard}
            alt="Dashboard at a Glance"
            className={`${
              activeCard === 1
                ? "active"
                : activeCard > 1
                ? "hide-up"
                : "hide-right"
            } follow-mouse`}
          />
          <img
            src={previewProperty}
            alt="Property & Unit Management"
            className={`${
              activeCard === 2
                ? "active"
                : activeCard > 2
                ? "hide-up "
                : "hide-right "
            } follow-mouse`}
          />
          <img
            src={previewChat}
            alt="In-App Chat for Simplified Communication"
            className={`${
              activeCard === 3
                ? "active"
                : activeCard > 3
                ? "hide-up"
                : "hide-right"
            } follow-mouse`}
          />
          <img
            src={previewTenant}
            alt="Tenant Directory"
            className={`${
              activeCard === 4
                ? "active"
                : activeCard > 4
                ? "hide-up"
                : "hide-right"
            } follow-mouse`}
          />
          <img
            src={previewReminders}
            alt="Reminders Feature"
            className={`${
              activeCard === 5
                ? "active"
                : activeCard > 5
                ? "hide-up"
                : "hide-right"
            } follow-mouse`}
          />
          <img
            src={previewMaintenance}
            alt="Maintenance Requests"
            className={`${
              activeCard === 6
                ? "active"
                : activeCard > 6
                ? "hide-up"
                : "hide-right"
            } follow-mouse`}
          />
        </div>
      </div>
      <div className="mobile-insights" ref={insightsMobile}>
        <div className="cards-wrapper">
          <div
            className={`feature-card-content ${
              activeMobileCard > 1 ? "hide-left" : ""
            }`}
          >
            <FeatureCard
              icon={graphIcon}
              title="Dashboard at a Glance"
              active={activeMobileCard === 1}
            />
            <p className="text-center">
              See all the essentials in one place. Landlords get instant
              insights into rent payments, tenants, upcoming dues, and expected
              revenue. Tenants can view their current dues and unit details with
              ease.
            </p>
            <img
              src={previewDashboard}
              alt="Dashboard at a Glance"
              className="feature-image"
            />
          </div>
          <div
            className={`feature-card-content ${
              activeMobileCard > 2 ? "hide-left" : ""
            }`}
          >
            <FeatureCard
              icon={houseIcon}
              title="Property & Unit Management"
              active={activeMobileCard === 2}
            />
            <p className="text-center">
              Gain a bird's eye view and detailed control over your entire
              portfolio.
            </p>
            <img
              src={previewProperty}
              alt="Property & Unit Management"
              className="feature-image"
            />
          </div>
          <div
            className={`feature-card-content ${
              activeMobileCard > 3 ? "hide-left" : ""
            }`}
          >
            <FeatureCard
              icon={chatIcon}
              title="In-App Chat for Simplified Communication"
              active={activeMobileCard === 3}
            />
            <p className="text-center">
              Centralized Messaging: Direct, trackable in-app conversations
              reduce communication clutter.
            </p>
            <img
              src={previewChat}
              alt="In-App Chat for Simplified Communication"
              className="feature-image"
            />
          </div>
          <div
            className={`feature-card-content ${
              activeMobileCard > 4 ? "hide-left" : ""
            }`}
          >
            <FeatureCard
              icon={userIcon}
              title="Tenant Directory"
              active={activeMobileCard === 4}
            />
            <p className="text-center">
              Easily manage tenant profiles with essential contact information
              and leasing details. Assign tenants to units and set rental terms.
              All in one place.
            </p>
            <img
              src={previewTenant}
              alt="Tenant Directory"
              className="feature-image"
            />
          </div>
          <div
            className={`feature-card-content ${
              activeMobileCard > 5 ? "hide-left" : ""
            }`}
          >
            <FeatureCard
              icon={checkIcon}
              title="Reminders Feature"
              active={activeMobileCard === 5}
            />
            <p className="text-center">
              Never miss a crucial date again with customizable notifications
              for payments, lease events, and maintenance.
            </p>
            <img
              src={previewReminders}
              alt="Reminders Feature"
              className="feature-image"
            />
          </div>
          <div
            className={`feature-card-content ${
              activeMobileCard > 6 ? "hide-left" : ""
            }`}
          >
            <FeatureCard
              icon={maintenanceIcon}
              title="Maintenance Requests"
              active={activeMobileCard === 6}
            />
            <p className="text-center">
              Streamline the process of submitting, tracking, and resolving
              maintenance issues.
            </p>
            <img
              src={previewMaintenance}
              alt="Maintenance Requests"
              className="feature-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyInsightsSection;
