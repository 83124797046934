const Component = ({ title, description, icon, active, className }) => {
  const classNameProp = className ?? "";
  return (
    <div className={`${active ? "active " : ""}feature-card ${classNameProp}`}>
      <span className="title-wrapper">
        <img src={icon} alt={title} />
        <h3>{title}</h3>
      </span>
      {description && <p className="description">{description}</p>}
    </div>
  );
};

export default Component;
