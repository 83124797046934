import Logo from "../../assets/images/logo.svg";
import { EXTERNAL_URLS } from "../../constants/appConstants";

const Footer = () => {
  return (
    <footer>
      <div className="footer-nav">
        <div className="footer-logo">
          <img src={Logo} alt="Happypropy's logo" />
        </div>
        <nav>
          <a href="#property-insights" className="nav-link">
            Property Insights
          </a>
          <a href="#advantages" className="nav-link">
            Advantages
          </a>
          <a href="#security" className="nav-link">
            Security
          </a>
        </nav>
        <a className="btn" href={EXTERNAL_URLS.SignUp}>
          Get started now
        </a>
      </div>
      <div className="footer-legal">
        <div className="copyright text-grey">
          <p>© 2024. HappyPropy. All rights reserved.</p>
        </div>
        <div className="legal">
          <span className="nav-link">Terms of Use</span>
          <span className="nav-link">Privacy Policy</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
