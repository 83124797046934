import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { EXTERNAL_URLS } from "../../constants/appConstants";
import { landingService } from "../../helpers/landingService";
import Header from "../../layout/Header/component";
import Footer from "../../layout/Footer/component";
import AdvantageCard from "../../components/advantageCard/component";

import mainVideo from "../../assets/videos/main-video.mp4";

import comprehensiveFlexibleIcon from "../../assets/images/comprehensive-flexible-icon.svg";
import directEfficientIcon from "../../assets/images/direct-efficient-icon.svg";
import smartAnalyticsIcon from "../../assets/images/smart-analytics-icon.svg";
import streamlinedTaskIcon from "../../assets/images/streamlined-task-icon.svg";

import PropertyInsightsSection from "../../components/propertyInsightSection/component";

const LandingPage = () => {
  const landingView = document.querySelector("#root");
  const [suscriptionPlans, setSuscriptionPlans] = useState({
    id: 0,
    yearly_off_percent: 0,
    monthly_amount: "0",
    yearly_amount: "0",
  });

  useEffect(() => {
    landingService.getSubscriptionPrice().then((response) => {
      if (response) {
        setSuscriptionPlans(response);
      }
    });
  }, []);

  landingView.addEventListener("scroll", headerBackgorund);

  function headerBackgorund() {
    const scroll = landingView.scrollTop;
    const header = landingView.querySelector("header");

    if (scroll > 100) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  }

  return (
    <>
      <Header />
      <main>
        <div className="blob-wrapper">
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
        </div>
        <div className="hero">
          <div className="hero-text">
            <h1 className="text-balanced">
              <span className="text-gradient">HappyPropy:</span> Your
              All-Encompassing Property Management Solution
            </h1>
            <p className="text-big">
              Manage Properties, Communicate Effectively, and Optimize
              Operations.
            </p>
          </div>
          <Link className="btn" target="_blank" to={EXTERNAL_URLS.SignUp}>
            Get started now
          </Link>
        </div>
        <div className="video">
          <video muted loop autoPlay>
            <source src={mainVideo} type="video/mp4" />
          </video>
        </div>
      </main>
      <PropertyInsightsSection />
      <section className="advantages" id="advantages">
        <div className="blob-wrapper">
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
        </div>
        <div className="advantages-text">
          <span className="badge">Advantages</span>
          <h1 className="text-center">
            Why <span className="text-gradient">HappyProppy</span> is Essential
          </h1>
        </div>
        <div className="advantages-cards">
          <div className="cards-row">
            <AdvantageCard
              icon={comprehensiveFlexibleIcon}
              title={"Comprehensive & Flexible Tools"}
              description={
                "Manage everything with one subscription, plus optional add-ons for extra messaging credits."
              }
            />
            <AdvantageCard
              icon={directEfficientIcon}
              title={"Direct, Efficient Communication"}
              description={
                "Use in-app chat to stay connected, schedule messages, and keep tenants informed."
              }
            />
          </div>
          <div className="cards-row">
            <AdvantageCard
              icon={smartAnalyticsIcon}
              title={"Smart Analytics"}
              description={
                "Track payments, rental performance, and property metrics to make smarter decisions."
              }
            />
            <AdvantageCard
              icon={streamlinedTaskIcon}
              title={"Streamlined Task Management"}
              description={
                "From maintenance tracking to tenant reminders, keep everything organized and on schedule with ease."
              }
            />
          </div>
        </div>
      </section>
      <section id="security">
        <div className="blob-wrapper">
          <div className="blob"></div>
        </div>
        <div className="security">
          <div className="security-wrapper">
            <div className="security-content">
              <div className="security-text">
                <h1 className="text-center">
                  <span className="text-gradient">Transparent</span>
                  <br />
                  Payment Terms
                </h1>
                <p className="text-center text-balanced">
                  No suprise fees. Cancel anytime.
                </p>
              </div>
              <div className="subscription-plans">
                <div className="plan">
                  <h2>${suscriptionPlans.monthly_amount}</h2>
                  <p>
                    per unit, <br /> paid monthly
                  </p>
                </div>
                <div className="plan">
                  <span className="badge">
                    Save {suscriptionPlans.yearly_off_percent}%
                  </span>
                  <h2 className="text-gradient">
                    ${suscriptionPlans.yearly_amount}
                  </h2>
                  <p>
                    per unit, <br /> paid annually
                  </p>
                </div>
              </div>
            </div>
            <Link className="btn" target="_blank" to={EXTERNAL_URLS.SignUp}>
              Get started now
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LandingPage;
